type IconProps = {
  className?: string
  icon: React.FC<{
    color?: string
    width?: string
    height?: string
  }>
}

export const IonIcon: React.FC<IconProps> = ({ icon, className = null }) => {
  const IconComponent = icon

  return (
    <>
      <span
        className={`icon inline-flex items-center justify-center ${className} stroke-2`}
      >
        <IconComponent color="currentColor" width="100%" height="100%" />
      </span>
    </>
  )
}
