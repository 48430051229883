import cn from 'classnames'
import { motion, Variants } from 'framer-motion'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { HTMLAttributeAnchorTarget } from 'react'

type Icon = JSX.Element

type CustomLinkProps = LinkProps & {
  variant?: 'primary' | 'secondary' | 'no-variant'
  animateIcon?: boolean
  target?: HTMLAttributeAnchorTarget
  forceExternal?: boolean
  className?: string
} & (
    | {
        children: Required<JSX.Element | string>
        icon?: Icon
        label?: string
      }
    | {
        children?: null
        label: string
        icon: Required<Icon>
      }
  )

const LinkWrapper: React.FC<CustomLinkProps> = (props) => {
  const { href, children, target, forceExternal } = props

  if (
    href.toString().startsWith('/') &&
    (!target || target === '_self') &&
    !forceExternal
  ) {
    return (
      <Link {...props} passHref>
        {children}
      </Link>
    )
  }
  return <>{children}</>
}

const CustomLink: React.FC<CustomLinkProps> = (props) => {
  const {
    children,
    variant = 'primary',
    icon,
    animateIcon = false,
    label,
    target,
    ...linkProps
  } = props

  const { href, className } = linkProps

  const iconVariants: Variants = {
    hover: {
      x: '0.25em',

      transition: { type: 'spring', duration: 0.4 },
    },
  }

  const router = useRouter()
  const active =
    router &&
    (href.toString() === router.pathname || href.toString() === router.asPath)
  return (
    <LinkWrapper {...props}>
      <span className="inline-block border-t-2 border-white border-opacity-0">
        <motion.a
          whileHover={animateIcon && 'hover'}
          // eslint-disable-next-line react/destructuring-assignment
          aria-label={label}
          href={href.toString()}
          target={target}
          rel={target && target !== '_self' ? 'noopener noreferrer' : undefined}
          className={cn(
            className,
            'inline-flex items-center justify-center border-b-2 border-opacity-0 focus:text-orange cursor-pointer outline-none transition-all duration-300',
            active
              ? {
                  'hover:text-orange': !!children,
                  'text-navy-900 border-navy-900': variant === 'primary',
                  'text-orange border-orange': variant === 'secondary',
                }
              : {
                  'hover:text-orange': !!children,
                  'text-orange border-orange': variant === 'primary',
                  'text-navy-900 border-navy-900': variant === 'secondary',
                }
          )}
        >
          <span className="flex-1">{children}</span>
          {icon && (
            <motion.span
              variants={iconVariants}
              className={cn('ml-1 w-5 h-5', animateIcon && 'mr-1')}
            >
              {icon}
            </motion.span>
          )}
        </motion.a>
      </span>
    </LinkWrapper>
  )
}

export { CustomLink as Link }
export type { CustomLinkProps as LinkProps }
