import classNames from 'classnames'
import { motion, Variants } from 'framer-motion'
import Link, { LinkProps } from 'next/link'
import React, { MouseEvent } from 'react'

export type ButtonVariants = 'default' | 'outlined' | 'ghost'

interface ButtonPropsBase {
  disabled?: boolean
  label: string
  variant?: ButtonVariants
  icon?: JSX.Element
  size?: 'small' | 'medium'
  iconOnly?: boolean
  animateIcon?: boolean
  className?: string
  iconFirst?: boolean
}

type RegularButtonProps = ButtonPropsBase & {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
}
type LinkButtonProps = ButtonPropsBase & LinkProps

export type ButtonProps = RegularButtonProps | LinkButtonProps

// eslint-disable-next-line react/require-default-props
type BaseComponentProps = ButtonProps & { className?: string }

const BaseComponent: React.FC<BaseComponentProps> = (props) => {
  const { children, className } = props
  const { label, disabled } = props

  if ('href' in props) {
    const { href } = props

    return (
      <Link href={href} passHref>
        <a className={className}>{children}</a>
      </Link>
    )
  }

  const { onClick } = props

  return (
    <button
      type="button"
      aria-label={label}
      disabled={disabled}
      onClick={!disabled ? onClick : null}
      className={className}
    >
      {children}
    </button>
  )
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    label,
    disabled = false,
    variant = 'default',
    size = 'medium',
    animateIcon = false,
    iconOnly = false,
    icon = null,
    iconFirst = false,
    className,
  } = props

  const buttonVariants: Variants = {
    hover: {},
  }

  const iconVariants: Variants = {
    hover: {
      x: '0.25em',
      transition: { type: 'spring', duration: 0.4 },
    },
  }

  return (
    <motion.span
      className="inline-flex"
      variants={buttonVariants}
      whileHover={!disabled && animateIcon ? 'hover' : null}
    >
      <BaseComponent
        {...props}
        className={classNames(
          'inline-flex items-center justify-center font-body font-bold leading-none focus:bg-orange rounded focus:outline-none transition-all focus:ring-4 focus:ring-mustard-500',
          iconOnly && 'rounded-full w-14 h-14',
          !iconOnly && size === 'small' && 'text-sm px-4 py-3',
          !iconOnly && size === 'medium' && 'text-base px-6 py-4',
          disabled && 'cursor-not-allowed',
          variant === 'default' && [
            {
              'border-orange bg-orange hover:bg-orange-800 hover:border-orange-800 focus:border-orange hover:shadow-400 text-white':
                !disabled,
              'border-navy-light-100 bg-navy-light-100 text-navy-600 cursor-not-allowed':
                disabled,
            },
          ],
          variant === 'outlined' && [
            'bg-white border-2',
            {
              'text-orange hover:bg-orange-800 border-2 border-orange hover:text-white hover:border-orange-800 focus:border-orange focus:text-white':
                !disabled,
              'text-navy-600 border-navy-light-400': disabled,
            },
          ],
          variant === 'ghost' && [
            {
              'text-orange hover:bg-orange-800 hover:border-orange-800 focus:border-orange hover:shadow-400 hover:text-white focus:text-white':
                !disabled,
              'border-navy-light-100 bg-navy-light-100 text-navy-600 cursor-not-allowed':
                disabled,
            },
          ],
          className
        )}
      >
        {!iconOnly && !iconFirst && <span className="flex-1">{label}</span>}
        {icon && (
          <motion.span
            variants={iconVariants}
            className={classNames('flex items-center justify-center', {
              'ml-2 w-5 h-5': !iconOnly && !iconFirst,
              'mr-2 w-5 h-5': !iconOnly && iconFirst,
              'w-6 h-6': iconOnly,
            })}
          >
            {icon}
          </motion.span>
        )}
        {!iconOnly && iconFirst && <span className="flex-1">{label}</span>}
      </BaseComponent>
    </motion.span>
  )
}
Button.defaultProps = {
  className: '',
}
