import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useUserData } from '../lib/hooks'

const isPrivatePage = (path: string): boolean => {
  const privatePaths = [
    '/admin',
    '/admin/registrations/[id]',
    '/admin/activities/[id]',
  ]
  return privatePaths.includes(path)
}
export function RouteGuard({ children }) {
  const [user, loading, error] = useUserData()
  const [unauthorized, setUnauthorized] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setUnauthorized(!user && isPrivatePage(router.pathname))
    if (!unauthorized) return
    router.push({
      pathname: '/login',
      query: { returnUrl: router.asPath },
    })
  }, [user, router.pathname, unauthorized])

  if (!isPrivatePage(router.pathname) || user) {
    return children
  }

  return (
    <div className="flex items-center justify-center w-full h-full bg-navy-light-100">
      <p className="font-display text-6xl font-black">Carregant...</p>
    </div>
  )
}
