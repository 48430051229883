import getConfig from 'next/config'
import firebase from 'firebase/app'
import 'firebase/auth'

const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} }

const firebaseConfig = {
  apiKey: 'AIzaSyB3uL3RPhLDNBWyI3U4qwu9XctSyvsxMVo',
  authDomain: 'omnium-embolicat.firebaseapp.com',
  projectId: 'omnium-embolicat',
  storageBucket: 'omnium-embolicat.appspot.com',
  messagingSenderId: '628228150358',
  appId: '1:628228150358:web:927a311791a5b00295d7f7',
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
  if (publicRuntimeConfig.useEmulator) {
    firebase.auth().useEmulator('http://localhost:9099')
  }
}

export const auth = firebase.auth()
