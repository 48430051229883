import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { auth } from '../lib/firebase'

const httpLink = createHttpLink({
  uri: '/api/graphql',
})

const authLink = setContext((_, { headers }) => {
  if (!auth.currentUser) {
    return { headers }
  }
  return auth.currentUser.getIdToken().then((token) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })
})

const cache = new InMemoryCache({
  typePolicies: {
    Activity: {
      fields: {
        registrations: {
          merge(existing, incoming) {
            return incoming
          },
        },
        learnerRegistrations: {
          merge(existing, incoming) {
            return incoming
          },
        },
        teacherRegistrations: {
          merge(existing, incoming) {
            return incoming
          },
        },
        pairings: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
  },
})

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache,
})

export default client
